import moment from "moment"
import "moment-timezone"

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export const Pad = d => {
  return d < 10 ? "0" + d.toString() : d.toString()
}

export const secondsToTime = value => {
  const sec = parseInt(value, 10) // convert value to number if it's string
  let hours = Math.floor(sec / 3600) // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
  let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return hours + ":" + minutes // Return is HH : MM : SS
}

export const convertUTCToTimezone = (
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm:ss a"
) => {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat)
}

export const returnWeekday = i => {
  return WeekDays[i] ? WeekDays[i] : null
}

// export default Pad;
